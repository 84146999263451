#nav-bar-header {
    font-family: 'Michroma', sans-serif;
    font-size: 20px;
};

#nav-text {
    font-family: 'Raleway', sans-serif;
}

#nav {
    background-color: #212321;
    color: white;
}