#about-video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 10;
    left: 0;
  }

#about-container {
    background-color: #212321;
    opacity: 0.7;
    color: white;
    filter: drop-shadow(7px 0px 13px #000);
    padding: 20px;
    border: transparent;
    
    align-items: center;
}

#about-header {
    font-family: 'Michroma', sans-serif;
    font-size: 30px;
    text-align: center;
}


.about-text {
    font-family: 'Raleway', sans-serif;
    text-align: center;
}

.cross-media {
    color: #719071;
    font-weight: bold;
}

